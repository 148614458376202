
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "broker-client-claims-listing",
  components: {},
  data() {
    return {
      claims: [
        {
          insured: "",
          claimNumber: "",
          policyType: "",
          entity: "",
          lossType: "",
          status: "",
          amount: "",
          settledAmount: "",
          outstandingAmount: "",
          lossDate: "",
          reportedDate: "",
          publicId: "",
          hasClaimNumber: false,
          isSettlementOfferAccepted: false,
          settlementOffer: {},
          isDischargeVoucherApproved: false,
          businessTypeShortCode: "",
          settlementOffers: "",
        },
      ],
    };
  },
  setup() {
    const {
      reinitialization,
      useReusableNavigation,
      displayDatatable,
    } = ResusableFunctions();

    onMounted(() => {
      setCurrentPageBreadcrumbs("Members Claims", [
        "Cooperative",
        "Members",
        "Claims",
      ]);

      displayDatatable(["basicExample"]);
    });

    return {
      useReusableNavigation,
      reinitialization,
    };
  },
  created() {
    this.getClaims();

    //Set page title
    document.title =
      "Cooperative: Members Claims | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    getClaims() {
      //Fetch list of claims
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.CLAIMS_ROUTE)
          .then(({ data }) => {
            // Assign data to props
            this.claims = data.data.claims;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
  },
});
